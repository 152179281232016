import cookieService from 'services/cookies'

interface UtmzParams {
  [key: string]: string
  utmgclid?: string
  utmcsr?: string
}

interface VisitorSources {
  [key: string]: string | boolean
  date: string
  other?: string | boolean
  adwords?: string | boolean
  direct?: string | boolean
}

const getUtmzParams = (): UtmzParams => {
  const params = {}
  const utmz = cookieService.getItem('__utmz')
  if (utmz) {
    const pairs = utmz.split('.').slice(4).join('.').split('|')
    for (const iterator of pairs) {
      const temp = iterator.split('=')
      params[temp[0]] = temp[1]
    }
  }
  return params
}

const isUserFromAdWords = (utmzParams: UtmzParams): boolean => !!utmzParams?.utmgclid
const isUserFromDirect = (utmzParams: UtmzParams): boolean => !!utmzParams?.utmcsr && utmzParams.utmcsr === '(direct)'

const getCurrentSource = (): VisitorSources => {
  let source: VisitorSources = { date: new Date().toJSON() }

  const utmzParams = getUtmzParams()
  if (Object.keys(utmzParams).length) {
    // other
    source = {
      ...source,
      other: true,
    }
  } else if (isUserFromAdWords(utmzParams)) {
    // adwords
    source = {
      ...source,
      adwords: true,
    }
  } else if (isUserFromDirect(utmzParams)) {
    // direct
    source = {
      ...source,
      direct: true,
    }
  } else {
    // campaign
    for (const paramName in utmzParams) {
      if (Object.prototype.hasOwnProperty.call(utmzParams, paramName)) {
        source[paramName] = utmzParams[paramName]
      }
    }
  }
  return source
}

const trackSources = (): void => {
  /**
   * In oryginal code source there was if statment that do not track source from:
   * 1. User that is logged in
   * 2. User that come from panel to LP
   * 3. User has cookie 'registered'
   */

  const sourcesText = cookieService.getItem('sources') || '[]'
  let sources = JSON.parse(sourcesText)
  sources.push(getCurrentSource())
  if (sources.length > 5) {
    sources = sources.slice(-5)
  }
  cookieService.setItem('sources', JSON.stringify(sources), 'None', cookieService.maximalCookieTTL)
}

function setupCookies(): void {
  // Allowed URL params that are stored as cookie.
  const allowedParamsIds = ['uap', 'xchangerId']
  cookieService.setupCookiesFromURL(allowedParamsIds)
  trackSources()
}

setupCookies()
